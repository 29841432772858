import GuidingQuestionnaireService, {
  GuidingQuestionnaireUser,
} from '../../services/guiding-questionnaire-service';
import { Box, Container, SlideFade, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import style from '../../assets/style.json';
import CompanyService, { Company } from '../../services/company-service';

function ModifyGuidingQuestionnaireUsers() {
  const { t } = useTranslation();

  const [users, setUsers] = useState<GuidingQuestionnaireUser[]>([]);
  const [companies, setCompanies] = useState<Company[]>([]);

  const { id } = useParams();

  useEffect(() => {
    updateUsers();
  }, []);

  const updateUsers = async () => {
    if (id) {
      const selectedQuestionnaireUsers =
        await GuidingQuestionnaireService.users(id);

      const companies = await CompanyService.list();

      setUsers(selectedQuestionnaireUsers);
      setCompanies(companies);
    }
  };

  // Split users by company ({ company: GuidingQuestionnaireUser[] })
  const usersByCompany: Record<string, GuidingQuestionnaireUser[]> =
    users.reduce((acc: Record<string, GuidingQuestionnaireUser[]>, user) => {
      const company = companies.find(
        (company) => company.id === user.companyId,
      );

      if (!company) {
        return acc;
      }

      if (!acc[company.name]) {
        acc[company.name] = [];
      }

      acc[company.name].push(user);

      return acc;
    }, {});

  console.log({ usersByCompany });

  return (
    <Container
      maxW="full"
      minH="100vh"
      position={'absolute'}
      top={'90px'}
      py={20}
      centerContent
    >
      <SlideFade in>
        <Text
          color={'white'}
          dir="rtl"
          marginBottom={'77px'}
          fontWeight={500}
          fontSize={32}
        >
          {t('modifyGuidingQuestionnaire.questionnaireUsers')}
        </Text>
      </SlideFade>

      <Box
        padding={'21px'}
        maxW={style['pc-view']}
        w="100%"
        bg="#fff"
        boxShadow={'0px 0px 25px rgba(62, 62, 62, 0.08)'}
        rounded="10px"
        display={'flex'}
        flexDirection={'column'}
        gap="20px"
      >
        <Box
          display={'flex'}
          flexDirection="column"
          gap={'21px'}
          rounded={'10px'}
          padding={'21px'}
          bg="#F5F8FA"
        >
          <Text
            fontSize={'22px'}
            fontWeight="600"
            color={'#0E314E'}
            lineHeight="30px"
          >
            {t('modifyGuidingQuestionnaire.generalDetails')}
          </Text>
          <Box className="grid grid-cols-3 gap-10">
            <Box className="flex flex-col gap-4">
              <h3 className="text-lg font-semibold">
                {t('modifyGuidingQuestionnaire.status.pending')}
              </h3>
              {Object.entries(usersByCompany).map(([company, users]) =>
                users.filter((u) => u.status === 'PENDING').length > 0 ? (
                  <Box key={company} className="flex flex-col gap-4">
                    <h4 className="text-lg font-semibold">{company}</h4>
                    {users
                      .filter((u) => u.status === 'PENDING')
                      .map((user, index) => (
                        <Box key={index} className="flex flex-row gap-10">
                          <Text className="text-lg text-blue-800">
                            {user.name || user.email || user.phone || user.id}
                          </Text>
                        </Box>
                      ))}
                  </Box>
                ) : null,
              )}
            </Box>
            <Box className="flex flex-col gap-4">
              <h3 className="text-lg font-semibold">
                {t('modifyGuidingQuestionnaire.status.read')}
              </h3>
              {Object.entries(usersByCompany).map(([company, users]) =>
                users.filter((u) => u.status === 'READ').length > 0 ? (
                  <Box key={company} className="flex flex-col gap-4">
                    <h4 className="text-lg font-semibold">{company}</h4>
                    {users
                      .filter((u) => u.status === 'READ')
                      .map((user, index) => (
                        <Box key={index} className="flex flex-row gap-10">
                          <Text className="text-lg text-blue-800">
                            {user.name || user.email || user.phone || user.id}
                          </Text>
                        </Box>
                      ))}
                  </Box>
                ) : null,
              )}
            </Box>
            <Box className="flex flex-col gap-4">
              <h3 className="text-lg font-semibold">
                {t('modifyGuidingQuestionnaire.status.done')}
              </h3>
              {Object.entries(usersByCompany).map(([company, users]) =>
                users.filter((u) => u.status === 'DONE').length > 0 ? (
                  <Box key={company} className="flex flex-col gap-4">
                    <h4 className="text-lg font-semibold">{company}</h4>
                    {users
                      .filter((u) => u.status === 'DONE')
                      .map((user, index) => (
                        <Box key={index} className="flex flex-row gap-10">
                          <Text className="text-lg text-blue-800">
                            {user.name || user.email || user.phone || user.id}
                          </Text>
                        </Box>
                      ))}
                  </Box>
                ) : null,
              )}
            </Box>
            {/* {users.map((user, index) => (
              <Box
                key={index}
                display={'flex'}
                flexDirection={'row'}
                gap={'10px'}
              >
                <Text fontSize={'18px'} color={'#0E314E'}>
                  {user.name}
                </Text>
                <Text fontSize={'18px'} color={'#0E314E'}>
                  {user.status}
                </Text>
              </Box>
            ))} */}
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default ModifyGuidingQuestionnaireUsers;

import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Container,
  SimpleGrid,
  Text,
  VStack,
  SlideFade,
  ScaleFade,
  useMediaQuery,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ExposureService from '../../services/exposure-service';
import CompanyService, { Company } from '../../services/company-service';
import UserService from '../../services/user-service';
import style from '../../assets/style.json';
import { PrimaryButton } from '../../components/Footer/styleComponent/PrimaryButton';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../locales/LocaleContext';

const ExposureByCompanies = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
	const [avgExposure, setAvgExposure] = useState<number | null>(null);
	const [categories, setCategories] = useState<any>({});
  const [exposureByCompanies, setExposureByCompanies] = useState<{ companyId: string, exposure: number }[]>([]);
  const [isPc] = useMediaQuery('(min-width: 1300px)');
  const { isLoading, setIsLoading } = useContext(LanguageContext);
  const [companies, setCompanies] = useState<Company[]>([]);

  console.log({ categories })

  const load = async () => {
    setIsLoading(true);

    try {
      const user = await UserService.info();

	  const userCompanies: string[] = user.subCompanies || [];

	  // If user has 0 companies, redirect to "/exposure"
	  if (!userCompanies.length) {
		navigate('/exposure');
		return;
	  }

	  const companies = await CompanyService.get(userCompanies);
	  const exposures = await Promise.all(
		userCompanies.map((companyId: string) => ExposureService.getById(companyId)),
	  ) as { total: number }[];

	  setCompanies(companies);
	  setExposureByCompanies(exposures.map((exposure, index) => ({ companyId: userCompanies[index], exposure: exposure.total })));

      let categories = { categories: {} };

     categories = await ExposureService.categories();
    //  setExposureByCompanies(await ExposureService.companies())
     console.log({ exposureByCompanies });

      setCategories(categories.categories);

	  if (user?.subCompanies && user?.subCompanies.length > 0) {
		const exposures = await Promise.all(
			user.subCompanies.map((companyId: string) => ExposureService.getById(companyId)),
		) as { total: number }[];

		const totalExposure = exposures.reduce((acc, exposure) => acc + exposure.total, 0);

		const avgExposure = totalExposure / user.subCompanies.length;
		console.log({ totalExposure, avgExposure })

		setAvgExposure(avgExposure);
	}
    } catch (error) {
      console.error('Error loading data:', error);
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    load();
  }, []);

  return (
    //@ts-ignore
    <Container
      maxW="full"
      position="absolute"
      top={'90px'}
      py={20}
      centerContent
    >
      <SlideFade in>
        <Text
          color={'white'}
          dir="rtl"
          marginBottom={'77px'}
          fontWeight={500}
          fontSize={32}
        >
          {t('exposure.performance')}
        </Text>
      </SlideFade>
      <Box
        w="100%"
        maxW={style['pc-view']}
        bg="#fff"
        p={6}
        rounded="10px"
        boxShadow={'0px 0px 25px rgba(62, 62, 62, 0.08)'}
      >
        <SlideFade in>
          <Box
            w="100%"
            display={'flex'}
            flexDirection={isPc ? 'row' : 'column'}
            justifyContent="center"
            alignItems="center"
            gap={isPc ? '30px' : '10px'}
            paddingBottom="20px"
          >
            <Box textAlign={isPc ? 'start' : 'center'} paddingTop="25px">
              <Text
                lineHeight={isPc ? '60px' : '25px'}
                fontWeight="400"
                color={'#A9A9A9'}
                fontSize={18}
              >
                {t('exposure.generalPerformance')}
              </Text>
            </Box>
            <CircularProgress
              mt={3}
              value={
                (avgExposure || 0) * 100
              }
              size="140px"
              thickness="10px"
              color={
                (avgExposure || 0) > 0.8
                  ? '#3FB2D2'
                  : (avgExposure || 0) > 0.4
                    ? '#F6A860'
                    : '#E55353'
              }
            >
              <CircularProgressLabel
                fontWeight="bold"
                fontSize={avgExposure === null ? 15 : 22}
                opacity={avgExposure === null ? 0.8 : 1}
              >
                {Object.keys(categories).length === 0
                  ? t('exposure.processing') + '...'
                  : ((avgExposure || 0) * 100).toFixed(0) + '%'}
              </CircularProgressLabel>
            </CircularProgress>
          </Box>
        </SlideFade>

        <Text
          lineHeight={isPc ? '60px' : '18px'}
          fontWeight="400"
          fontSize={18}
          textAlign={isPc ? 'center' : 'start'}
          color="#A9A9A9"
        >
          {t('exposure.companies')}
        </Text>

        <Box
          w="100%"
          minH={240}
          py={'21px'}
        >
            <SimpleGrid w="100%" columns={isPc ? 3 : 1} spacing={4}>
              {companies.map((company, sectionIndex) => (
                <Box
                  bg="#F5F8FA"
                  key={'company-' + sectionIndex}
                  w="100%"
                  display={'flex'}
                  justifyContent="space-between"
                  padding={'20px'}
                  rounded="10px"
                >
                  <Box
                    height={'100%'}
                    display="flex"
                    flexDirection={'column'}
                    justifyContent="space-between"
                    alignItems={'flex-start'}
                  >
                    <Text
                      fontWeight="600"
                      fontSize={18}
                      opacity={1}
                      color="#0E314E"
                    >
                      {company.name}
                    </Text>
                    <PrimaryButton
                      onClick={() =>
                        navigate(
                          `/admin/exposure/${company.id}`,
                        )
                      }
                    >
                      {t('exposure.check')}
                    </PrimaryButton>
                  </Box>
                  <Graph exposure={exposureByCompanies.find((c) => c.companyId === company.id)?.exposure || 0} />
                </Box>
              ))}
            </SimpleGrid>
        </Box>
      </Box>
    </Container>
  );
};

const Graph = ({ exposure }: { exposure: number }) => {
	return <ScaleFade in initialScale={0}>
	<VStack w="100%" spacing={1}>
	  <CircularProgress
		value={(exposure || 0) * 100}
		size="100px"
		thickness="10px"
		color={
		  exposure > 0.8
			? '#3FB2D2'
			: exposure > 0.4
			  ? '#F6A860'
			  : '#E55353'
		}
	  >
		<CircularProgressLabel fontWeight="bold" fontSize={19}>
		  {((exposure || 0) * 100).toFixed(0)}%
		</CircularProgressLabel>
	  </CircularProgress>
	</VStack>
  </ScaleFade>
}

export default ExposureByCompanies;

import {
  Container,
  Text,
  Box,
  Button,
  Input,
  VStack,
  SimpleGrid,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Checkbox,
  Spinner,
  SlideFade,
  useMediaQuery,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Switch,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import CompanyService, { Company } from '../../services/company-service';
import UtilSrvice from '../../services/util-service';
import style from '../../assets/style.json';
import { CirclePlusIcon } from '../../assets/CirclePlusIcon';
import { TrashIcon } from '../../assets/TrashIcon';
import { PrimaryButton } from '../../components/Footer/styleComponent/PrimaryButton';
import { ArrowDownIcon } from '../../assets/ArrowDownIcon';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../locales/LocaleContext';

export const Th1 = (props: any) => (
  <Text
    as="th"
    textTransform="uppercase"
    fontSize="xs"
    color="gray.500"
    fontWeight="medium"
    px={4}
    {...props}
  />
);

export const Td1 = (props: any) => (
  <Box
    as="td"
    color="gray.900"
    p={4}
    borderBottom="1px solid"
    borderBottomColor="gray.100"
    {...props}
  />
);

export const Tr1 = (props: any) => (
  <Box
    as="tr"
    backgroundColor="gray.50"
    borderTopLeftRadius={8}
    borderTopRightRadius={8}
    borderBottom="1px solid"
    borderBottomColor="gray.200"
    height="40px"
    {...props}
  />
);

export const Table1 = (props: any) => {
  return (
    <Box
      as="table"
      textAlign="left"
      backgroundColor="white"
      ml={0}
      mr={0}
      borderRadius={8}
      boxShadow="0px 4px 10px rgba(0, 0, 0, 0.05)"
      {...props}
    />
  );
};

const SkeletonRow = (width: any) => (
  <Box as="tr">
    <Td>
      <Skeleton height="10px" w={width} my={4} />
    </Td>
    <Td>
      <Skeleton height="10px" w={width} my={4} />
    </Td>
    <Td>
      <Skeleton height="10px" w={width} my={4} />
    </Td>
    <Td>
      <Skeleton height="10px" w={width} my={4} />
    </Td>
    <Td>
      <SkeletonCircle size="6" width={'40px'} borderRadius={'10px'} />
    </Td>
  </Box>
);

const ModifyCompany = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  // const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState<any>({});
  const [submitting, setSubmitting] = useState(false);
  const [isPc] = useMediaQuery('(min-width: 1300px)');
  const [isMobile] = useMediaQuery('(max-width: 676px)');

  const [selectedLanguage, setSelectedLanguage] = useState<any>('en');

  const [companies, setCompanies] = useState<Company[]>([]);

  const { isLoading, setIsLoading, locale, setLocale } =
    useContext(LanguageContext);

  console.log({ companies });

  const load = async () => {
    if (id) {
      setIsLoading(true);

      const [res, companiesRes] = await Promise.all([
        CompanyService.read(id),
        CompanyService.list(),
      ]);
      setCompany({
        ...res,
        employees: (res?.employees ?? []).filter((e: any) => e.name && e.phone),
        language: res.language || 'en',
      });

      setCompanies(companiesRes);
      setIsLoading(false);
    }
  };

  const toggleIdFromArr = (arr: [any], id: any) => {
    if (!Array.isArray(arr)) return [id];
    if (arr.some((item: any) => item === id))
      return arr.filter((item: any) => item !== id);
    return [...arr, id];
  };

  useEffect(() => {
    load();
  }, []);

  const submit = async () => {
    if (!company.name?.trim()) {
      Swal.fire(
        t('modifyCompany.invalidName'),
        t('modifyCompany.enterCompanyName'),
        'warning',
      );
      return;
    }
    if (
      company.exposureCategories?.some(
        (categorey: any) => !categorey.name?.trim(),
      )
    ) {
      Swal.fire(
        t('modifyCompany.invalidName'),
        t('modifyCompany.enterRiskNames'),
        'warning',
      );
      return;
    }
    if (
      company.exposureCategories?.some(
        (categorey: any, index: number) =>
          company.exposureCategories?.filter(
            (c: any, index: number) =>
              categorey?.name?.trim() === c?.name?.trim(),
          ).length > 1,
      )
    ) {
      Swal.fire(
        t('modifyCompany.invalidName'),
        t('modifyCompany.uniqueRiskNames'),
        'warning',
      );
      return;
    }
    if (company.roles?.some((role: any) => !role.name?.trim())) {
      Swal.fire(
        t('modifyCompany.invalidName'),
        t('modifyCompany.enterRoleNames'),
        'warning',
      );
      return;
    }
    if (
      company.roles?.some(
        (role: any, index: number) =>
          company.roles?.filter(
            (r: any, index: number) => role?.name?.trim() === r?.name?.trim(),
          ).length > 1,
      )
    ) {
      Swal.fire(
        t('modifyCompany.invalidName'),
        t('modifyCompany.uniqueRoleNames'),
        'warning',
      );
      return;
    }
    if (company.employees?.some((employee: any) => !employee.name?.trim())) {
      Swal.fire(
        t('modifyCompany.invalidEmployee'),
        t('modifyCompany.invalidEmployeeName'),
        'warning',
      );
      return;
    }
    if (company.employees?.some((employee: any) => !employee.phone?.trim())) {
      Swal.fire(
        t('modifyCompany.invalidPhoneNumber'),
        t('modifyCompany.enterPhoneNumbers'),
        'warning',
      );
      return;
    }
    if (!company.language) {
      company.language = 'en';
    }
    setSubmitting(true);
    try {
      console.log({ company });
      await CompanyService.modify(company);
      navigate('/admin/companies');
    } catch (e) {}
    setSubmitting(false);
  };

  return (
    <Container
      position="absolute"
      top={'90px'}
      maxW="full"
      minH="100vh"
      py={20}
      centerContent
    >
      <SlideFade in>
        <Text
          color={'white'}
          dir="rtl"
          marginBottom={'77px'}
          fontWeight={500}
          fontSize={32}
        >
          {id ? t('modifyCompany.editCompany') : t('modifyCompany.addCompany')}
        </Text>
      </SlideFade>

      {isLoading && id !== undefined ? (
        <>
          <Box
            padding={'22px'}
            width={'100%'}
            maxW={style['pc-view']}
            rounded="10px"
            bg={'#fff'}
            boxShadow="0px 0px 25px rgba(62, 62, 62, 0.08);"
            display={'flex'}
            flexDirection="column"
            gap={'20px'}
          >
            <Box bg="#F5F8FA" p={'21px'} rounded="10px">
              <Text fontSize={'22px'} fontWeight="600" mb={'22px'}>
                {t('modifyCompany.companyDetails')}
              </Text>
              <VStack w="100%" spacing={2}>
                <SimpleGrid
                  w="100%"
                  columns={{ base: 1, md: 2 }}
                  spacingX={4}
                  spacingY={2}
                >
                  <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('modifyCompany.companyName')}
                    </Text>
                    <Skeleton height={'54px'} />
                  </Box>
                  <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('modifyCompany.field')}
                    </Text>
                    <Skeleton height={'54px'} />
                  </Box>
                  <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('modifyCompany.siteLink')}
                    </Text>
                    <Skeleton height={'54px'} />
                  </Box>
                  <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('modifyCompany.active')}
                    </Text>
                    <SkeletonCircle
                      size="6"
                      width={'40px'}
                      borderRadius={'10px'}
                    />
                  </Box>
                </SimpleGrid>
              </VStack>
            </Box>

            <Box bg="#F5F8FA" p={'21px'} rounded="10px">
              <Text fontSize={'22px'} fontWeight="600" mb={'22px'}>
                {t('modifyCompany.risks')}
              </Text>
              <SimpleGrid columns={isPc ? 3 : 1} spacing={2}>
                <Box pos="relative">
                  <Text
                    marginBottom={'5px'}
                    fontSize={'14px'}
                    color="#aaa"
                    fontWeight="14px"
                  >
                    {t('modifyCompany.riskName')}
                  </Text>
                  <Skeleton height={'54px'} />
                </Box>
                <Box pos="relative">
                  <Text
                    marginBottom={'5px'}
                    fontSize={'14px'}
                    color="#aaa"
                    fontWeight="14px"
                  >
                    {t('modifyCompany.riskName')}
                  </Text>
                  <Skeleton height={'54px'} />
                </Box>
                <Box pos="relative">
                  <Text
                    marginBottom={'5px'}
                    fontSize={'14px'}
                    color="#aaa"
                    fontWeight="14px"
                  >
                    {t('modifyCompany.riskName')}
                  </Text>
                  <Skeleton height={'54px'} />
                </Box>
                {isPc && (
                  <Box pos="relative">
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('modifyCompany.riskName')}
                    </Text>
                    <Skeleton height={'54px'} />
                  </Box>
                )}
                <Box
                  border="1.2521px dashed #3FB2D2"
                  rounded="10px"
                  justifyContent="center"
                  alignItems={'center'}
                  display={'flex'}
                  gap={'16px'}
                  marginTop="20px"
                  padding={'10px'}
                  color={'#3FB2D2'}
                  cursor="pointer"
                >
                  <Skeleton height="40px" width="200px" borderRadius="2xl" />
                </Box>
              </SimpleGrid>
            </Box>

            <Box bg="#F5F8FA" p={'21px'} rounded="10px">
              <Text fontSize={'22px'} fontWeight="600" mb={'22px'}>
                {t('modifyCompany.roles')}
              </Text>
              <SimpleGrid w="100%" columns={isPc ? 3 : 1} spacing={2}>
                <Skeleton h="134px" rounded="10px" />
                <Skeleton h="134px" rounded="10px" />
                <Skeleton h="134px" rounded="10px" />
              </SimpleGrid>
            </Box>

            <Box bg="#F5F8FA" p={'21px'} rounded="10px">
              <SlideFade in>
                <Box
                  width={'100%'}
                  display="flex"
                  justifyContent={'space-between'}
                  alignItems="center"
                  mb={'7px'}
                >
                  <Text fontSize={'22px'} fontWeight="600">
                    {t('modifyCompany.employees')}
                  </Text>
                  {company?.employees && company?.employees?.length > 0 && (
                    <Button
                      minW={'188px'}
                      textAlign="center"
                      fontSize="18px"
                      fontWeight={700}
                      rounded={'100px'}
                      borderWidth={'1.5px'}
                      borderColor={'#3FB2D2'}
                      bg={'transparent'}
                      color={'#3FB2D2'}
                      onClick={() =>
                        setCompany({
                          ...company,
                          employees: [
                            ...(company.employees || []),
                            {
                              id: (Math.random() + 1).toString(36).substring(2),
                              isActive: true,
                            },
                          ],
                        })
                      }
                    >
                      <Box gap={'17px'} display={'flex'} alignItems={'center'}>
                        <Text>{t('modifyCompany.addEmployee')}</Text>
                        <CirclePlusIcon />
                      </Box>
                    </Button>
                  )}
                </Box>

                <Table>
                  <thead>
                    <Tr>
                      <Th>Name</Th>
                      <Th>Phone</Th>
                      <Th>ROLE</Th>
                      <Th>Reporting</Th>
                      <Th>Active</Th>
                    </Tr>
                  </thead>
                  <tbody>
                    <SkeletonRow width="75px" />
                    <SkeletonRow width="125px" />
                    <SkeletonRow width="50px" />
                    <SkeletonRow width="100px" />
                    <SkeletonRow width="20px" />
                  </tbody>
                </Table>
              </SlideFade>
            </Box>

            <Box display={'flex'} flexDirection="row-reverse">
              <Skeleton
                minW={isPc ? '200px' : 'full'}
                h="60px"
                rounded={'100px'}
              />
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box
            padding={'22px'}
            width={'100%'}
            maxW={style['pc-view']}
            rounded="10px"
            bg={'#fff'}
            boxShadow="0px 0px 25px rgba(62, 62, 62, 0.08);"
            display={'flex'}
            flexDirection="column"
            gap={'20px'}
          >
            <Box bg="#F5F8FA" p={'21px'} rounded="10px">
              <Text fontSize={'22px'} fontWeight="600" mb={'22px'}>
                {t('modifyCompany.companyDetails')}
              </Text>
              <VStack w="100%" spacing={2}>
                <SimpleGrid
                  w="100%"
                  columns={{ base: 1, md: 2 }}
                  spacingX={style.gap}
                  spacingY={style.gap}
                >
                  <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('modifyCompany.companyName')}
                    </Text>
                    <Input
                      bg={'#fff'}
                      border={'1px solid #E2E2E2'}
                      rounded="10px"
                      height={'54px'}
                      maxLength={50}
                      placeholder="Name *"
                      isRequired
                      value={company.name}
                      onChange={(e) =>
                        setCompany({ ...company, name: e.target.value })
                      }
                    />
                  </Box>
                  <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('modifyCompany.field')}
                    </Text>
                    <Input
                      maxLength={30}
                      bg={'#fff'}
                      height={'54px'}
                      border={'1px solid #E2E2E2'}
                      rounded="10px"
                      placeholder="Field  (e.g.: Construction, Medical, Education)"
                      value={company.field}
                      onChange={(e) =>
                        setCompany({ ...company, field: e.target.value })
                      }
                    />
                  </Box>
                  <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('modifyCompany.siteLink')}
                    </Text>
                    <Input
                      maxLength={50}
                      bg={'#fff'}
                      height={'54px'}
                      border={'1px solid #E2E2E2'}
                      rounded="10px"
                      placeholder="Website"
                      value={company.website}
                      onChange={(e) =>
                        setCompany({ ...company, website: e.target.value })
                      }
                    />
                  </Box>

                  <SimpleGrid columns={isMobile ? 2 : 4}>
                    <Box>
                      <Text
                        marginBottom={'5px'}
                        fontSize={'14px'}
                        color="#aaa"
                        fontWeight="14px"
                      >
                        {t('modifyCompany.active')}
                      </Text>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          paddingTop: '13px',
                        }}
                      >
                        <div dir="ltr">
                          <Switch
                            defaultChecked={company?.isActive}
                            onChange={() => {
                              const companyClone = { ...company };
                              companyClone.isActive = !company?.isActive;
                              setCompany(companyClone);
                            }}
                          />
                        </div>
                      </Box>
                    </Box>

                    <Box>
                      <Text
                        marginBottom={'5px'}
                        fontSize={'14px'}
                        color="#aaa"
                        fontWeight="14px"
                      >
                        {t('modifyCompany.language')}
                      </Text>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Select
                          width="60px"
                          //   value={i18n.language}
                          value={company.language || 'en'}
                          onChange={(e) =>
                            setCompany({ ...company, language: e.target.value })
                          }
                          background={'white'}
                          className={locale === 'en' ? 'en' : 'he'}
                          minW={isPc ? '113px' : '70px'}
                        >
                          <option
                            value="en"
                            style={{ color: '#000' }}
                            className="En"
                          >
                            EN
                          </option>
                          <option
                            value="he"
                            style={{ color: '#000' }}
                            className="He"
                          >
                            HE
                          </option>
                        </Select>
                      </Box>
                    </Box>
                    <Box>
                      <Text
                        marginBottom={'5px'}
                        fontSize={'14px'}
                        color="#aaa"
                        fontWeight="14px"
                      >
                        {t('modifyCompany.tasksSms')}
                      </Text>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          paddingTop: '13px',
                        }}
                      >
                        <div dir="ltr">
                          <Switch
                            defaultChecked={company?.tasksSms}
                            onChange={() => {
                              const companyClone = { ...company };
                              companyClone.tasksSms = !company?.tasksSms;
                              setCompany(companyClone);
                            }}
                          />
                        </div>
                      </Box>
                    </Box>
                    <Box>
                      <Text
                        marginBottom={'5px'}
                        fontSize={'14px'}
                        color="#aaa"
                        fontWeight="14px"
                      >
                        {t('modifyCompany.tasksSmsTime')}
                      </Text>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Input
                          size="md"
                          type="time"
                          background={'white'}
                          onChange={(e) => {
                            setCompany({
                              ...company,
                              tasksSmsTime: e.target.value,
                            });
                          }}
                          value={company.tasksSmsTime || ''}
                          disabled={!company?.tasksSms}
                        />
                      </Box>
                    </Box>
                  </SimpleGrid>
                </SimpleGrid>
              </VStack>
            </Box>

            <Box bg="#F5F8FA" p={'21px'} rounded="10px">
              <Text fontSize={'22px'} fontWeight="600" mb={'22px'}>
                {t('modifyCompany.risks')}
              </Text>

              <SimpleGrid columns={isPc ? 3 : 1} spacing={2}>
                {company?.exposureCategories?.map(
                  (category: any, i: number) => (
                    <Box pos="relative" key={'category-' + category.id + i}>
                      <Text
                        marginBottom={'5px'}
                        fontSize={'14px'}
                        color="#aaa"
                        fontWeight="14px"
                      >
                        {t('modifyCompany.riskName')}
                      </Text>
                      <Box
                        bg="#fff"
                        border="1.2521px solid #E2E2E2"
                        rounded="10px"
                        display={'flex'}
                        alignItems="center"
                      >
                        <Input
                          border={'none'}
                          value={category?.name}
                          height={'54px'}
                          isRequired
                          onChange={(e) => {
                            const companyClone = { ...company };
                            companyClone.exposureCategories[i].name =
                              e.target.value;
                            setCompany(companyClone);
                          }}
                          maxLength={50}
                        />
                        <Button
                          bg="transparent"
                          color={'#3FB2D2'}
                          onClick={async () => {
                            const result = await Swal.fire({
                              title:
                                t('modifyCompany.deleteRisk') +
                                ' ' +
                                name +
                                '?',
                              text: t('modifyCompany.deleteWarning'),
                              input: 'password',
                              inputAttributes: {
                                autocapitalize: 'off',
                                placeholder: t('modifyCompany.enterPassword'),
                              },
                              showCancelButton: true,
                              confirmButtonColor: '#3085d6',
                              cancelButtonColor: '#d33',
                              cancelButtonText: t('modifyCompany.cancel'),
                              confirmButtonText: t('modifyCompany.delete'),
                            });
                            if (result.isConfirmed) {
                              try {
                                await CompanyService.checkAdminCode(
                                  result.value,
                                );
                                const companyClone = { ...company };
                                companyClone.exposureCategories =
                                  companyClone.exposureCategories.filter(
                                    (c: any) => c.id !== category.id,
                                  );
                                setCompany(companyClone);
                              } catch (err: any) {
                                Swal.fire(
                                  '',
                                  t('modifyCompany.invalidPassword'),
                                  'error',
                                );
                              }
                            }
                          }}
                        >
                          <TrashIcon />
                        </Button>
                      </Box>
                    </Box>
                  ),
                )}

                <Box
                  border="1.2521px dashed #3FB2D2"
                  rounded="10px"
                  justifyContent="center"
                  alignItems={'center'}
                  display={'flex'}
                  gap={'16px'}
                  marginTop="20px"
                  padding={'10px'}
                  color={'#3FB2D2'}
                  cursor="pointer"
                  onClick={() =>
                    setCompany({
                      ...company,
                      exposureCategories: [
                        ...(company.exposureCategories || []),
                        {
                          id: (Math.random() + 1).toString(36).substring(2),
                          name:
                            'Risk ' +
                            ((company.exposureCategories || []).length + 1),
                        },
                      ],
                    })
                  }
                >
                  <Text fontWeight="700" fontSize={'18px'}>
                    {t('modifyCompany.addRisk')}
                  </Text>
                  <CirclePlusIcon />
                </Box>
              </SimpleGrid>
            </Box>

            <Box bg="#F5F8FA" p={'21px'} rounded="10px">
              <Text fontSize={'22px'} fontWeight="600" mb={'22px'}>
                {t('modifyCompany.roles')}
              </Text>
              <SimpleGrid w="100%" columns={isPc ? 3 : 1} spacing={2}>
                {company?.roles?.map((role: any, ix: number) => (
                  <Box
                    pos="relative"
                    key={'role-' + role.id + ix}
                    bg="#fff"
                    rounded={'10px'}
                    border="1.2521px solid #E2E2E2"
                    padding={'14px'}
                    display={'flex'}
                    flexDirection="column"
                    gap={'16px'}
                  >
                    <Box
                      rounded={'10px'}
                      bg="#F5F8FA"
                      display="flex"
                      alignItems={'center'}
                    >
                      <Input
                        value={role?.name}
                        height={'54px'}
                        border="none"
                        onChange={(e) => {
                          const companyClone = { ...company };
                          companyClone.roles[ix].name = e.target.value;
                          setCompany(companyClone);
                        }}
                        maxLength={20}
                      />
                      <Button
                        color={'#3FB2D2'}
                        bg={'transparent'}
                        onClick={() => {
                          const companyClone = { ...company };
                          companyClone.roles = companyClone.roles.filter(
                            (r: any) => r.id !== role.id,
                          );
                          setCompany(companyClone);
                        }}
                      >
                        <TrashIcon />
                      </Button>
                    </Box>

                    <Box
                      display={'flex'}
                      justifyContent="space-between"
                      alignItems={'center'}
                    >
                      <Box display="flex" flexDirection={'column'}>
                        <Checkbox
                          isChecked={role.showExposure}
                          size="lg"
                          bg="white"
                          fontSize="16px"
                          colorScheme="white"
                          borderColor="#3FB2D2"
                          iconColor="#3FB2D2"
                          iconSize={'17px'}
                          fontWeight={400}
                          onChange={(e) => {
                            const companyClone = { ...company };
                            companyClone.roles[ix].showExposure =
                              e.target.checked;
                            setCompany(companyClone);
                          }}
                        >
                          {t('modifyCompany.showRiskProgress')}
                        </Checkbox>
                        <Checkbox
                          isChecked={role.showNewslatter}
                          colorScheme="white"
                          borderColor="#3FB2D2"
                          iconColor="#3FB2D2"
                          iconSize={'17px'}
                          size="lg"
                          onChange={(e) => {
                            const companyClone = { ...company };
                            companyClone.roles[ix].showNewslatter =
                              e.target.checked;
                            setCompany(companyClone);
                          }}
                        >
                          {t('modifyCompany.showNewsletters')}
                        </Checkbox>
                        <Checkbox
                          isChecked={role.showQuestionnaires}
                          colorScheme="white"
                          borderColor="#3FB2D2"
                          iconColor="#3FB2D2"
                          iconSize={'17px'}
                          size="lg"
                          onChange={(e) => {
                            const companyClone = { ...company };
                            companyClone.roles[ix].showQuestionnaires =
                              e.target.checked;
                            setCompany(companyClone);
                          }}
                        >
                          {t('modifyCompany.showQuestionnaires')}
                        </Checkbox>
                      </Box>
                      <Menu closeOnSelect={false}>
                        <MenuButton
                          as={Button}
                          bg="#3FB2D2"
                          color="#fff"
                          rounded={'100px'}
                          lineHeight="42px"
                          minWidth={'112px'}
                        >
                          {t('modifyCompany.reporting')}
                        </MenuButton>
                        <MenuList overflowY="auto" maxH={'300px'}>
                          <Text px={3} fontWeight="bold" mb={2}>
                            {t('modifyCompany.roles')}
                          </Text>
                          {company?.roles
                            ?.filter((x: any) => x.id !== role.id)
                            ?.filter((x: any) => x.name)
                            ?.map((r: any, i: number) => (
                              <MenuItem key={'role-' + i + '-' + r.id}>
                                <Checkbox
                                  mr={2}
                                  colorScheme="white"
                                  borderColor="#3FB2D2"
                                  iconColor="#3FB2D2"
                                  iconSize={'17px'}
                                  size="lg"
                                  defaultChecked={(
                                    role?.parentIds || []
                                  ).includes(r.id)}
                                  id={r.id}
                                  name={r.id}
                                  value={
                                    (role?.parentIds || []).includes(r.id)
                                      ? 'checked'
                                      : 'unchecked'
                                  }
                                  onChange={(e) => {
                                    const companyClone = { ...company };
                                    companyClone.roles[ix].parentIds =
                                      companyClone.roles[ix].parentIds || [];
                                    if (!e.target.checked) {
                                      companyClone.roles[ix].parentIds =
                                        companyClone.roles[ix].parentIds.filter(
                                          (p: any) => p !== r.id,
                                        );
                                    } else {
                                      if (
                                        !companyClone.roles[
                                          ix
                                        ].parentIds.includes(r.id)
                                      ) {
                                        companyClone.roles[ix].parentIds.push(
                                          r.id,
                                        );
                                      }
                                    }
                                    setCompany(companyClone);
                                  }}
                                >
                                  {r.name}
                                </Checkbox>
                              </MenuItem>
                            ))}
                        </MenuList>
                      </Menu>
                    </Box>
                  </Box>
                ))}

                <Box
                  border="1.2521px dashed #3FB2D2"
                  rounded="10px"
                  minH="134px"
                  color={'#3FB2D2'}
                  cursor="pointer"
                  display={'flex'}
                  alignItems="center"
                  justifyContent="center"
                  gap={'15px'}
                  onClick={() => {
                    setCompany({
                      ...company,
                      roles: [
                        ...(company.roles || []),
                        {
                          id: (Math.random() + 1).toString(36).substring(2),
                          name:
                            'New Role ' + ((company.roles || []).length + 1),
                        },
                      ],
                    });
                  }}
                >
                  <Text fontSize={'18px'} fontWeight="700">
                    {t('modifyCompany.addRole')}
                  </Text>
                  <CirclePlusIcon />
                </Box>
              </SimpleGrid>
            </Box>

            <Box bg="#F5F8FA" p={'21px'} rounded="10px">
              <SlideFade in>
                <Box
                  width={'100%'}
                  display="flex"
                  justifyContent={'space-between'}
                  alignItems="center"
                  mb={'7px'}
                >
                  <Text fontSize={'22px'} fontWeight="600">
                    {t('modifyCompany.employees')}
                  </Text>
                  {company?.employees && company?.employees?.length > 0 && (
                    <Button
                      minW={'188px'}
                      textAlign="center"
                      fontSize="18px"
                      fontWeight={700}
                      rounded={'100px'}
                      borderWidth={'1.5px'}
                      borderColor={'#3FB2D2'}
                      bg={'transparent'}
                      color={'#3FB2D2'}
                      onClick={() =>
                        setCompany({
                          ...company,
                          employees: [
                            ...(company.employees || []),
                            {
                              id: (Math.random() + 1).toString(36).substring(2),
                              isActive: true,
                            },
                          ],
                        })
                      }
                    >
                      <Box gap={'17px'} display={'flex'} alignItems={'center'}>
                        <Text>{t('modifyCompany.addEmployee')}</Text>
                        <CirclePlusIcon />
                      </Box>
                    </Button>
                  )}
                </Box>
                {!company?.employees?.length ? (
                  <Button
                    minW={'188px'}
                    textAlign="center"
                    fontSize="18px"
                    width={'100%'}
                    fontWeight={700}
                    rounded={'10px'}
                    borderWidth={'1.5px'}
                    borderStyle="dashed"
                    height="78px"
                    borderColor={'#3FB2D2'}
                    bg={'transparent'}
                    color={'#3FB2D2'}
                    onClick={() =>
                      setCompany({
                        ...company,
                        employees: [
                          ...(company.employees || []),
                          { id: (Math.random() + 1).toString(36).substring(2) },
                        ],
                      })
                    }
                  >
                    <Box gap={'17px'} display={'flex'} alignItems={'center'}>
                      <Text>{t('modifyCompany.addEmployee')}</Text>
                      <CirclePlusIcon />
                    </Box>
                  </Button>
                ) : null}
                {company.employees && company.employees.length > 0 && (
                  <>
                    {isPc ? (
                      <div className="overflow-x-scroll h-fit overflow-y-hidden">
                        <Table>
                          <Thead borderBottom={'1.5px solid #DFDFDF'}>
                            <Tr>
                              <Th
                                fontSize={'16px'}
                                fontWeight={700}
                                lineHeight={'29px'}
                                paddingBottom="12px"
                                textAlign="start"
                              >
                                {t('modifyCompany.name')}
                              </Th>
                              <Th
                                fontSize={'16px'}
                                fontWeight={700}
                                lineHeight={'29px'}
                                paddingBottom="12px"
                                textAlign="start"
                              >
                                {t('modifyCompany.phone')}
                              </Th>
                              <Th
                                fontSize={'16px'}
                                fontWeight={700}
                                lineHeight={'29px'}
                                paddingBottom="12px"
                                textAlign="start"
                              >
                                {t('modifyCompany.email')}
                              </Th>
                              <Th
                                fontSize={'16px'}
                                fontWeight={700}
                                lineHeight={'29px'}
                                paddingBottom="12px"
                                textAlign="start"
                              >
                                {t('modifyCompany.role')}
                              </Th>
                              <Th
                                fontSize={'16px'}
                                fontWeight={700}
                                lineHeight={'29px'}
                                paddingBottom="12px"
                                textAlign="start"
                              >
                                {t('modifyCompany.subCompanies')}
                              </Th>
                              <Th
                                fontSize={'16px'}
                                fontWeight={700}
                                lineHeight={'29px'}
                                paddingBottom="12px"
                                textAlign="start"
                              >
                                {t('modifyCompany.reporting')}
                              </Th>
                              <Th
                                fontSize={'16px'}
                                fontWeight={700}
                                lineHeight={'29px'}
                                paddingBottom="12px"
                                textAlign="start"
                              >
                                {t('modifyCompany.active')}
                              </Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {company?.employees?.map(
                              (employee: any, i: number) => {
                                return (
                                  <Tr
                                    key={employee.id}
                                    rounded={'10px'}
                                    border={'none'}
                                    position="relative"
                                  >
                                    <Td
                                      minWidth={150}
                                      roundedRight={'10px'}
                                      bg={i % 2 === 1 ? 'white' : '#F5F8FA'}
                                      border={'none'}
                                      textAlign="start"
                                      p={1.5}
                                    >
                                      <Input
                                        placeholder={t('modifyCompany.name')}
                                        borderColor="#E2E2E2"
                                        bg={'white'}
                                        rounded={'10px'}
                                        fontSize="16px"
                                        fontWeight={400}
                                        paddingInline="12px"
                                        paddingBlock="30px"
                                        value={employee.name}
                                        onChange={(e) => {
                                          const companyClone = { ...company };
                                          companyClone.employees[i].name =
                                            e.target.value;
                                          setCompany(companyClone);
                                        }}
                                        maxLength={50}
                                      />
                                    </Td>
                                    <Td
                                      bg={i % 2 === 1 ? 'white' : '#F5F8FA'}
                                      border={'none'}
                                      textAlign="start"
                                      p={1.5}
                                    >
                                      <PhoneInput
                                        country={'il'}
                                        inputClass="login-phone-input"
                                        containerStyle={{
                                          minWidth: '160px',
                                        }}
                                        value={employee.phone}
                                        onChange={(phoneNumber) => {
                                          const companyClone = { ...company };
                                          companyClone.employees[i].phone =
                                            phoneNumber;
                                          setCompany(companyClone);
                                        }}
                                      />
                                    </Td>
                                    <Td
                                      bg={i % 2 === 1 ? 'white' : '#F5F8FA'}
                                      border={'none'}
                                      textAlign="start"
                                      p={1.5}
                                    >
                                      <Input
                                        placeholder={t('modifyCompany.email')}
                                        borderColor="#E2E2E2"
                                        width={'220px'} // was 350
                                        type="email"
                                        bg={'white'}
                                        rounded={'10px'}
                                        fontSize="16px"
                                        fontWeight={400}
                                        paddingInline="12px"
                                        paddingBlock="30px"
                                        value={employee.email}
                                        onChange={(e) => {
                                          const companyClone = { ...company };
                                          companyClone.employees[i].email =
                                            e.target.value;
                                          setCompany(companyClone);
                                        }}
                                      />
                                    </Td>

                                    <Td
                                      bg={i % 2 === 1 ? 'white' : '#F5F8FA'}
                                      border={'none'}
                                      textAlign="start"
                                      p={1.5}
                                    >
                                      <Menu closeOnSelect={false}>
                                        <MenuButton
                                          as={Box}
                                          bg="white"
                                          height={'54px'}
                                          border="1px solid #e2e2e2"
                                          rounded="10px"
                                          width={'220px'} // was 350
                                        >
                                          <Box
                                            padding="12px 17px"
                                            display="flex"
                                            flexDirection={'row'}
                                            alignItems="center"
                                            width={'full'}
                                            justifyContent="space-between"
                                          >
                                            <Box width={'calc(100% - 20px)'}>
                                              <Text
                                                textOverflow={'ellipsis'}
                                                width={'100%'}
                                                overflow={'hidden'}
                                                whiteSpace="nowrap"
                                              >
                                                {company?.roles
                                                  ?.filter((r: any) =>
                                                    employee?.roleIds?.includes(
                                                      r.id,
                                                    ),
                                                  )
                                                  .map((r: any) => r.name)
                                                  .join(',') ||
                                                  t('modifyCompany.chooseRole')}
                                              </Text>
                                            </Box>
                                            <Box flexShrink={0}>
                                              <ArrowDownIcon />
                                            </Box>
                                          </Box>
                                        </MenuButton>
                                        <MenuList
                                          overflowY="auto"
                                          maxH={'300px'}
                                          width={'150px'}
                                        >
                                          <Text px={3} fontWeight="bold" mb={2}>
                                            {t('modifyCompany.roles')}
                                          </Text>
                                          {company?.roles
                                            ?.filter((r: any) => r.name)
                                            .map((r: any) => (
                                              <MenuItem
                                                key={'role-' + i + '-' + r.id}
                                              >
                                                <Checkbox
                                                  colorScheme="white"
                                                  borderColor="#3FB2D2"
                                                  iconColor="#3FB2D2"
                                                  iconSize={'17px'}
                                                  size="lg"
                                                  mr={2}
                                                  isChecked={employee?.roleIds?.includes(
                                                    r.id,
                                                  )}
                                                  onChange={(e) => {
                                                    const companyClone = {
                                                      ...company,
                                                    };
                                                    companyClone.employees[
                                                      i
                                                    ].roleIds = toggleIdFromArr(
                                                      companyClone.employees[i]
                                                        .roleIds,
                                                      r.id,
                                                    );
                                                    setCompany(companyClone);
                                                  }}
                                                >
                                                  {r.name}
                                                </Checkbox>
                                              </MenuItem>
                                            ))}
                                        </MenuList>
                                      </Menu>
                                    </Td>

                                    <Td
                                      bg={i % 2 === 1 ? 'white' : '#F5F8FA'}
                                      border={'none'}
                                      textAlign="start"
                                      p={1.5}
                                    >
                                      <Menu closeOnSelect={false}>
                                        <MenuButton
                                          as={Box}
                                          bg="white"
                                          height={'54px'}
                                          border="1px solid #e2e2e2"
                                          rounded="10px"
                                          width={'220px'} // was 350
                                        >
                                          <Box
                                            padding="12px 17px"
                                            display="flex"
                                            flexDirection={'row'}
                                            alignItems="center"
                                            width={'full'}
                                            justifyContent="space-between"
                                          >
                                            <Box width={'calc(100% - 20px)'}>
                                              <Text
                                                textOverflow={'ellipsis'}
                                                width={'100%'}
                                                overflow={'hidden'}
                                                whiteSpace="nowrap"
                                              >
                                                {companies
                                                  ?.filter((r) =>
                                                    employee?.subCompanies?.includes(
                                                      r.id,
                                                    ),
                                                  )
                                                  .map((r: any) => r.name)
                                                  .join(',') ||
                                                  t(
                                                    'modifyCompany.subCompanies',
                                                  )}
                                              </Text>
                                            </Box>
                                            <Box flexShrink={0}>
                                              <ArrowDownIcon />
                                            </Box>
                                          </Box>
                                        </MenuButton>

                                        <MenuList
                                          overflowY="auto"
                                          maxH={'300px'}
                                          width={'150px'}
                                        >
                                          <Text px={3} fontWeight="bold" mb={2}>
                                            {t('modifyCompany.subCompanies')}
                                          </Text>
                                          {companies.map((r) => (
                                            <MenuItem
                                              key={'company-' + i + '-' + r.id}
                                            >
                                              <Checkbox
                                                colorScheme="white"
                                                borderColor="#3FB2D2"
                                                iconColor="#3FB2D2"
                                                iconSize={'17px'}
                                                size="lg"
                                                mr={2}
                                                isChecked={employee?.subCompanies?.includes(
                                                  r.id,
                                                )}
                                                onChange={(e) => {
                                                  const companyClone = {
                                                    ...company,
                                                  };
                                                  companyClone.employees[
                                                    i
                                                  ].subCompanies =
                                                    toggleIdFromArr(
                                                      companyClone.employees[i]
                                                        .subCompanies,
                                                      r.id,
                                                    );
                                                  setCompany(companyClone);
                                                }}
                                              >
                                                {r.name}
                                              </Checkbox>
                                            </MenuItem>
                                          ))}
                                        </MenuList>
                                      </Menu>
                                    </Td>

                                    <Td
                                      minWidth={150}
                                      bg={i % 2 === 1 ? 'white' : '#F5F8FA'}
                                      border={'none'}
                                      textAlign="start"
                                      fontSize={'16px'}
                                      fontWeight="400"
                                      maxWidth={'30%'}
                                      p={1.5}
                                    >
                                      {company?.roles
                                        ?.filter((r: any) =>
                                          employee.roleIds?.some(
                                            (id: any) => id === r.id,
                                          ),
                                        )
                                        .map((role: any) =>
                                          role.parentIds?.map(
                                            (x: string) =>
                                              company.roles.find(
                                                (r: any) => r.id === x,
                                              )?.name,
                                          ),
                                        )
                                        .reduce((acc: any, rolesNames: any) => {
                                          rolesNames?.forEach((r: any) => {
                                            if (
                                              !acc.some(
                                                (name: string) => name === r,
                                              )
                                            )
                                              acc.push(r);
                                          });
                                          return acc;
                                        }, [])
                                        .join(', ')}
                                    </Td>
                                    <Td
                                      minWidth={50} // was 150
                                      bg={i % 2 === 1 ? 'white' : '#F5F8FA'}
                                      border={'none'}
                                      textAlign="start"
                                      fontSize={'16px'}
                                      fontWeight="400"
                                      maxWidth={'30%'}
                                      p={1.5}
                                    >
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'row-reverse',
                                        }}
                                        dir="ltr"
                                      >
                                        <Switch
                                          defaultChecked={employee?.isActive}
                                          onChange={() => {
                                            const companyClone = { ...company };
                                            companyClone.employees[i].isActive =
                                              !employee?.isActive;
                                            setCompany(companyClone);
                                          }}
                                        />
                                      </Box>
                                    </Td>

                                    <Td
                                      bg={i % 2 === 1 ? 'white' : '#F5F8FA'}
                                      border={'none'}
                                      textAlign="end"
                                      roundedLeft={'10px'}
                                      p={1.5}
                                    >
                                      <Button
                                        color="#3FB2D2"
                                        bg={'transparent'}
                                        onClick={async () => {
                                          const result = await Swal.fire({
                                            title:
                                              t(
                                                'modifyCompany.deleteEmployee',
                                              ) +
                                              employee.name +
                                              '?',
                                            text: t(
                                              'modifyCompany.deleteWarning',
                                            ),
                                            input: 'password',
                                            inputAttributes: {
                                              autocapitalize: 'off',
                                              placeholder: t(
                                                'modifyCompany.enterPassword',
                                              ),
                                            },
                                            showCancelButton: true,
                                            confirmButtonColor: '#3085d6',
                                            cancelButtonColor: '#d33',
                                            cancelButtonText: t(
                                              'modifyCompany.cancel',
                                            ),
                                            confirmButtonText: t(
                                              'modifyCompany.delete',
                                            ),
                                          });
                                          if (result.isConfirmed) {
                                            try {
                                              await CompanyService.checkAdminCode(
                                                result.value,
                                              );
                                              const companyClone = {
                                                ...company,
                                              };
                                              companyClone.employees =
                                                companyClone.employees.filter(
                                                  (r: any) =>
                                                    r.id !== employee.id,
                                                );
                                              setCompany(companyClone);
                                            } catch (err: any) {
                                              Swal.fire(
                                                '',
                                                t(
                                                  'modifyCompany.invalidPassword',
                                                ),
                                                'error',
                                              );
                                            }
                                          }
                                        }}
                                      >
                                        <TrashIcon />
                                      </Button>
                                    </Td>
                                  </Tr>
                                );
                              },
                            )}
                          </Tbody>
                        </Table>
                      </div>
                    ) : (
                      <Box display={'flex'} flexDirection="column" gap={'25px'}>
                        {company?.employees?.map((employee: any, i: number) => (
                          <Box
                            display={'flex'}
                            flexDirection="column"
                            gap={'20px'}
                            bg="white"
                            paddingInline={'15px'}
                            paddingTop={'15px'}
                            rounded="10px"
                          >
                            <Box>
                              <Text
                                marginBottom={'5px'}
                                fontSize={'14px'}
                                color="#aaa"
                                fontWeight="14px"
                              >
                                {t('modifyCompany.employeeName')}
                              </Text>
                              <Input
                                placeholder={t('modifyCompany.name')}
                                borderColor="#E2E2E2"
                                bg={'white'}
                                rounded={'10px'}
                                fontSize="16px"
                                fontWeight={400}
                                paddingInline="12px"
                                paddingBlock="30px"
                                value={employee.name}
                                onChange={(e) => {
                                  const companyClone = { ...company };
                                  companyClone.employees[i].name =
                                    e.target.value;
                                  setCompany(companyClone);
                                }}
                                maxLength={50}
                              />
                            </Box>
                            <Box>
                              <Text
                                marginBottom={'5px'}
                                fontSize={'14px'}
                                color="#aaa"
                                fontWeight="14px"
                              >
                                {t('modifyCompany.role')}
                              </Text>
                              <Menu closeOnSelect={false}>
                                <MenuButton
                                  as={Box}
                                  bg="white"
                                  height={'54px'}
                                  border="1px solid #e2e2e2"
                                  rounded="10px"
                                >
                                  <Box
                                    padding="12px 17px"
                                    display="flex"
                                    flexDirection={'row'}
                                    alignItems="center"
                                    width={'full'}
                                    justifyContent="space-between"
                                  >
                                    <Box width={'calc(100% - 20px)'}>
                                      <Text
                                        textOverflow={'ellipsis'}
                                        width={'100%'}
                                        overflow={'hidden'}
                                        whiteSpace="nowrap"
                                      >
                                        {company?.roles
                                          ?.filter((r: any) =>
                                            employee?.roleIds?.includes(r.id),
                                          )
                                          .map((r: any) => r.name)
                                          .join(',') ||
                                          t('modifyCompany.chooseRole')}
                                      </Text>
                                    </Box>
                                    <Box flexShrink={0}>
                                      <ArrowDownIcon />
                                    </Box>
                                  </Box>
                                </MenuButton>
                                <MenuList overflowY="auto" maxH={'300px'}>
                                  <Text px={3} fontWeight="bold" mb={2}>
                                    {t('modifyCompany.roles')}
                                  </Text>
                                  {company?.roles
                                    ?.filter((r: any) => r.name)
                                    .map((r: any) => (
                                      <MenuItem key={'role-' + i + '-' + r.id}>
                                        <Checkbox
                                          colorScheme="white"
                                          borderColor="#3FB2D2"
                                          iconColor="#3FB2D2"
                                          iconSize={'17px'}
                                          size="lg"
                                          mr={2}
                                          isChecked={employee?.roleIds?.includes(
                                            r.id,
                                          )}
                                          onChange={(e) => {
                                            const companyClone = { ...company };
                                            companyClone.employees[i].roleIds =
                                              toggleIdFromArr(
                                                companyClone.employees[i]
                                                  .roleIds,
                                                r.id,
                                              );
                                            setCompany(companyClone);
                                          }}
                                        >
                                          {r.name}
                                        </Checkbox>
                                      </MenuItem>
                                    ))}
                                </MenuList>
                              </Menu>
                            </Box>

                            <Accordion border={'none'} allowMultiple={true}>
                              <AccordionItem border={'none'}>
                                <AccordionButton
                                  display={'flex'}
                                  justifyContent={'center'}
                                  alignItems={'center'}
                                  fontSize="16px"
                                  fontWeight={400}
                                  color="#3FB2D2"
                                  gap={'9px'}
                                >
                                  <Box
                                    display={'flex'}
                                    justifyContent="center"
                                    alignItems={'center'}
                                    fontSize="16px"
                                    fontWeight={400}
                                    color="#3FB2D2"
                                    gap={'9px'}
                                  >
                                    {t('modifyCompany.moreDetails')}
                                    <AccordionIcon />
                                  </Box>
                                </AccordionButton>
                                <AccordionPanel
                                  display={'flex'}
                                  flexDirection="column"
                                  gap={'20px'}
                                  paddingInline="0px"
                                  paddingBottom={'15px'}
                                >
                                  <Box>
                                    <Text
                                      marginBottom={'5px'}
                                      fontSize={'14px'}
                                      color="#aaa"
                                      fontWeight="14px"
                                    >
                                      {t('modifyCompany.phone')}
                                    </Text>
                                    <PhoneInput
                                      country={'il'}
                                      inputClass="login-phone-input"
                                      value={employee.phone}
                                      onChange={(phoneNumber) => {
                                        const companyClone = { ...company };
                                        companyClone.employees[i].phone =
                                          phoneNumber;
                                        setCompany(companyClone);
                                      }}
                                    />
                                  </Box>
                                  <Box>
                                    <Text
                                      marginBottom={'5px'}
                                      fontSize={'14px'}
                                      color="#aaa"
                                      fontWeight="14px"
                                    >
                                      {t('modifyCompany.email')}
                                    </Text>
                                    <Input
                                      placeholder={t('modifyCompany.email')}
                                      borderColor="#E2E2E2"
                                      bg={'white'}
                                      rounded={'10px'}
                                      fontSize="16px"
                                      fontWeight={400}
                                      paddingInline="12px"
                                      paddingBlock="30px"
                                      value={employee.email}
                                      onChange={(e) => {
                                        const companyClone = { ...company };
                                        companyClone.employees[i].email =
                                          e.target.value;
                                        setCompany(companyClone);
                                      }}
                                      maxLength={50}
                                    />
                                  </Box>

                                  <Box>
                                    <Text
                                      marginBottom={'5px'}
                                      fontSize={'14px'}
                                      color="#aaa"
                                      fontWeight="14px"
                                    >
                                      {t('modifyCompany.reporting')}
                                    </Text>
                                    <Text
                                      color={'#2D2F32'}
                                      fontSize="18px"
                                      fontWeight={'400'}
                                    >
                                      {company?.roles
                                        ?.filter((r: any) =>
                                          employee.roleIds?.some(
                                            (id: any) => id === r.id,
                                          ),
                                        )
                                        .map((role: any) =>
                                          role.parentIds?.map(
                                            (x: string) =>
                                              company.roles.find(
                                                (r: any) => r.id === x,
                                              )?.name,
                                          ),
                                        )
                                        .reduce((acc: any, rolesNames: any) => {
                                          rolesNames?.forEach((r: any) => {
                                            if (
                                              !acc.some(
                                                (name: string) => name === r,
                                              )
                                            )
                                              acc.push(r);
                                          });
                                          return acc;
                                        }, [])
                                        .join(', ')}
                                    </Text>
                                  </Box>
                                  <Box>
                                    <Text
                                      marginBottom={'5px'}
                                      fontSize={'14px'}
                                      color="#aaa"
                                      fontWeight="14px"
                                    >
                                      {t('modifyCompany.active')}
                                    </Text>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row-reverse',
                                      }}
                                      dir="ltr"
                                    >
                                      <Switch
                                        defaultChecked={employee?.isActive}
                                        onChange={() => {
                                          const companyClone = { ...company };
                                          companyClone.employees[i].isActive =
                                            !employee?.isActive;
                                          setCompany(companyClone);
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                  <Box
                                    display={'flex'}
                                    flexDirection="row-reverse"
                                  >
                                    <Button
                                      color="#3FB2D2"
                                      bg={'transparent'}
                                      display="flex"
                                      alignItems={'center'}
                                      gap="8px"
                                      onClick={() => {
                                        const companyClone = { ...company };
                                        companyClone.employees =
                                          companyClone.employees.filter(
                                            (r: any) => r.id !== employee.id,
                                          );
                                        setCompany(companyClone);
                                      }}
                                    >
                                      {t('modifyCompany.delete')}
                                      <TrashIcon />
                                    </Button>
                                  </Box>
                                </AccordionPanel>
                              </AccordionItem>
                            </Accordion>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </>
                )}
              </SlideFade>
            </Box>
            <Box display={'flex'} flexDirection="row-reverse">
              <Button
                color={'white'}
                minW={isPc ? '200px' : 'full'}
                height="60px"
                textAlign="center"
                border="3px solid #FFFFFF"
                background={'#3FB2D2'}
                fontSize="18px"
                fontWeight={700}
                rounded={'100px'}
                filter="drop-shadow(0px 4px 30px rgba(63, 178, 210, 0.44))"
                onClick={submit}
              >
                {t('modifyCompany.approve')}
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Container>
  );
};

export default ModifyCompany;

export function InfoIcon2(props: any) {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
	  width={18}
	  height={18}
      y="0px"
      viewBox="0 0 488.9 488.9"
      style={{
        transform: `scale(${props.scale ? props.scale : 1})`,
      }}
    >
      {/* <g>
        <g> */}
          <path
            d="M239.15,0c31.9,0,57.7,25.8,57.7,57.7s-25.8,57.7-57.7,57.7s-57.7-25.8-57.7-57.7S207.25,0,239.15,0z M291.65,151.6h-1.5
			h-92.8h-3.4c-19,0-34.3,15.4-34.3,34.3l0,0c0,19,15.4,34.3,34.3,34.3h3.4v200h-37.7v68.7h169.6v-68.7h-37.5V151.6H291.65z"
			fill={props.color ? props.color : "#3FB2D2"}
          />
        {/* </g>
      </g> */}
      {/* <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g> */}
    </svg>
  );
}

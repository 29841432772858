import axios from "axios";
import Swal from "sweetalert2";
import { appConfig } from "../configs/app";

export type ExposureCategory = {
	id: string;
	name: string;
	_id: string;
};

export type CompanyRole = {
	companyId: string;
	createdAt: string;
	id: string;
	name: string;
	parentIds: string[];
	showExposure: boolean;
	showNewslatter: boolean;
	showQuestionnaires: boolean;
	updatedAt: string;
	__v: 0;
	_id: string;
};

export type Company = {
	createdAt: string;
	exposureCategories: ExposureCategory[];
	field: string;
	id: string;
	isActive: boolean;
	name: string;
	onboardingIncludeRoleIds: string[];
	roles: CompanyRole[];
	updatedAt: "2024-07-11T13:25:14.338Z";
	__v: 0;
	_id: "65d629c12335bec24e1b80f2";
};

// biome-ignore lint/complexity/noStaticOnlyClass: <explanation>
class CompanyService {
	static async read(id: string) {
		const response = await axios.get(`${appConfig.apiUrl}/company/${id}`, {
			headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
		});
		const data = response.data;
		return data;
	}

	static async delete(name: string, id: string) {
		const result = await Swal.fire({
			title: `למחוק את החברה "${name}"?`,
			text: "לאחר המחיקה לא יהיה ניתן לשחזר את המידע.",
			input: "password",
			inputAttributes: {
				autocapitalize: "off",
				placeholder: "הקש סיסמה",
			},
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			cancelButtonText: "ביטול",
			confirmButtonText: "מחיקה",
		});
		if (result.isConfirmed) {
			console.log(result);

			try {
				await axios.delete(`${appConfig.apiUrl}/company/${id}`, {
					data: { password: result.value },
					headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
				});
			} catch (err: any) {
				Swal.fire("", err.response.data.message, "error");
			}
		}
	}

	static async list() {
		const response = await axios.get(`${appConfig.apiUrl}/companies`, {
			headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
		});
		const data = response.data;
		return data;
	}

	static async get(companies: string[]) {
		const response = await axios.get(`${appConfig.apiUrl}/companies`, {
			headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
			params: { companies: companies.join(',') },
		});
		const data = response.data;
		return data;
	}

	static async checkAdminCode(code: string) {
		await axios.post(`${appConfig.apiUrl}/company/checkAdminCode`, {
			data: { password: code },
			headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
		});
	}

	static async modify(info: any = {}) {
		try {
			const response = await axios.post(`${appConfig.apiUrl}/company`, info, {
				headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
			});
			console.log(response);
			if (response.status > 400) {
			}

			const data = response.data;
			return data;
		} catch (err: any) {
			Swal.fire("שגיאה", err?.response?.data?.message, "error");
			throw err;
		}
	}

	static async getOnboardingList(info: any = {}) {
		try {
			const response = await axios.get(
				`${appConfig.apiUrl}/onboarding-questionnaire`,
				{
					headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
				},
			);

			const data = response.data;
			return data;
		} catch (err: any) {
			Swal.fire("שגיאה", err?.response?.data?.message, "error");
			throw err;
		}
	}

	static async getOnboardingQuestionnaire() {
		try {
			const response = await axios.get(
				`${appConfig.apiUrl}/my-onboarding-questionnaire`,
				{
					headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
				},
			);

			const data = response.data;
			return data;
		} catch (err: any) {
			Swal.fire("שגיאה", err?.response?.data?.message, "error");
			throw err;
		}
	}

	static async createOnboardingQuestionnaire(
		questions: any[],
		name: string,
		lang: string,
	) {
		try {
			const response = await axios.post(
				`${appConfig.apiUrl}/onboarding-questionnaire`,
				{
					id: "",
					name: name,
					questions: questions.map((question) => {
						return {
							...question,
							id: "",
						};
					}),
					lang,
				},
				{
					headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
				},
			);

			const data = response.data;
			return data;
		} catch (err: any) {
			Swal.fire("שגיאה", err?.response?.data?.message, "error");
			throw err;
		}
	}

	static async updateOnboardingQuestionnaire(
		id: string,
		questions: any[],
		name: string,
		lang: string,
	) {
		try {
			const response = await axios.put(
				`${appConfig.apiUrl}/onboarding-questionnaire/${id}`,
				{
					id: id,
					name: name,
					questions: questions.map((question) => {
						return {
							...question,
							id: "",
						};
					}),
					lang,
				},
				{
					headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
				},
			);

			const data = response.data;
			return data;
		} catch (err: any) {
			Swal.fire("שגיאה", err?.response?.data?.message, "error");
			throw err;
		}
	}

	static async deleteOnboardingQuestionnaire(id: string) {
		try {
			const response = await axios.delete(
				`${appConfig.apiUrl}/onboarding-questionnaire/${id}`,
				{
					headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
				},
			);

			const data = response.data;
			return data;
		} catch (err: any) {
			Swal.fire("שגיאה", err?.response?.data?.message, "error");
			throw err;
		}
	}

	static async getUnlistedUsers(companyId: string) {
		try {
			const response = await axios.get(
				`${appConfig.apiUrl}/unlisted-users/${companyId}`,
				{
					headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
				},
			);

			const data = response.data;
			return data;
		} catch (err: any) {
			Swal.fire("שגיאה", err?.response?.data?.message, "error");
			throw err;
		}
	}

	static async createUnlistedUsers(companyId: string, phones: string[]) {
		try {
			const response = await axios.post(
				`${appConfig.apiUrl}/unlisted-users`,
				{
					companyId,
					phones,
				},
				{
					headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
				},
			);

			const data = response.data;
			return data;
		} catch (err: any) {
			Swal.fire("שגיאה", err?.response?.data?.message, "error");
			throw err;
		}
	}

	static async deleteUnlistedUser(companyId: string, userId: string) {
		try {
			const response = await axios.post(
				`${appConfig.apiUrl}/delete-unlisted-user`,
				{
					companyId,
					userId,
				},
				{
					headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
				},
			);

			const data = response.data;
			return data;
		} catch (err: any) {
			Swal.fire("שגיאה", err?.response?.data?.message, "error");
			throw err;
		}
	}

	static async setCompanyOnboardingQuestionnaire(
		companyId: string,
		onboardingQuestionnaireId: string,
		onboardingIncludeRoleIds: string[],
	) {
		try {
			const response = await axios.post(
				`${appConfig.apiUrl}/set-company-onboarding-questionnaire`,
				{
					companyId,
					onboardingQuestionnaireId,
					onboardingIncludeRoleIds,
				},
				{
					headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
				},
			);

			const data = response.data;
			return data;
		} catch (err: any) {
			Swal.fire("שגיאה", err?.response?.data?.message, "error");
			throw err;
		}
	}

	static async getOnboardingQuestionnaireById(id: string) {
		try {
			const response = await axios.get(
				`${appConfig.apiUrl}/onboarding-questionnaire/${id}`,
				{
					headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
				},
			);

			const data = response.data;
			return data;
		} catch (err: any) {
			Swal.fire("שגיאה", err?.response?.data?.message, "error");
			throw err;
		}
	}
}

export default CompanyService;
